/**
 * 示例-列表页-带多tab标签
 * luxinwen
 * 2023-01
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-tab">
        <Tabs v-model="tabName" @on-click="clickTabs" :animated="false">
          <TabPane label="所有商品" name="-1"></TabPane>
          <TabPane label="已上架" name="0"></TabPane>
          <TabPane label="已下架" name="1"></TabPane>
          <TabPane label="回收站" name="2"></TabPane>
        </Tabs>
      </div>
      <div class="page-main-content" v-grant="'OSP_TEST_LIST_SAVE'">
        <Button type="primary" @click="addData">新增信息</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_TEST_LIST_VIEW'">查看</Button>
            <Button @click="editData(params.row, false)" v-grant="'OSP_TEST_LIST_UPDATE'">编辑</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_TEST_LIST_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <!-- 编辑表单 -->
    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
  </div>
</template>

<script>
  import { TEST_STATUS } from '@/util/enum';
  import editDrawer from '../edit/edit-drawer.vue';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        tableColumns: [
          {
            title: '标题',
            key: 'name'
          },
          {
            title: '编码',
            key: 'code'
          },
          {
            title: '状态',
            render: (h, params) => {
              return h('span', this.statusList[params.row.status]);
            }
          },
          {
            title: '创建时间',
            key: 'time'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        tabName: '-1',
        statusList: TEST_STATUS
      };
    },
    computed: {
      filterColumns() {
        let val = this.tableColumns;
        let grant = [this.$grant.OSP_TEST_LIST_VIEW, this.$grant.OSP_TEST_LIST_UPDATE, this.$grant.OSP_TEST_LIST_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        }
        return val;
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, {
          type: this.tabName,
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.test.queryTestList,
          mock: true,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 点击切换tab
       */
      clickTabs(name) {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {};
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 删除
       */
      removeData(row) {
        this.$confirm(`是否确定删除【${row.name}】？`).then(() => {
          this.$axios({
            url: this.$api.test.deleteTest,
            mock: true,
            data: {
              id: row.id
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>